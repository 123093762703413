import {Controller} from '@hotwired/stimulus';

const templates = {
    /**
     * Important! Changes to the menu DOM structure must also be adopted to
     * templates/themes/wieland/element/contactform.html.twig
     */
    tab: {
        template: `
            <input
                class="contact-form-radio-button sr-only"
                id="{{ type }}"
                type="radio"
                name="type"
                value="{{ type }}"
                required
                {{ checked }}
            >
            <label for="{{ type }}"
            data-action="click->contact#changeContactPersonType" data-contact-type-param="{{ type }}"
            >{{ label }}</label>
        `,
    },
    dropdown: {
        template: `
             <div
                class="contact-form-contact-person {{ is-active }}"
                data-type="{{ type }}"
                data-contact-target="contactPersonWrapper"
             >
                <div class="dropdown contact-dropdown">
                    <div class="contact-dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static">
                        <div
                            data-contact-target="contactPersonToggle"
                            data-type="{{ type }}"
                        >
                            <img class="lazy"
                                 data-src="/build/images/placeholder/wieland-logo.png"
                                 alt="Wieland logo placeholder" width="40" height="40">
                            <span>{{ dropdownToggle }}</span>
                        </div>
                        <i class="fa-light fa-angle-down"></i>
                    </div>
                    <ul class="dropdown-menu">
                        {{ contactList }}
                    </ul>
                </div>
                <select
                    class="sr-only"
                    name="contact_id"
                    {{ required }}
                ></select>
            </div>
        `,
    },
    contactItem: {
        template: `
            <li>
                <div class="dropdown-item"
                data-action="click->contact#selectContactPerson"
                data-contact-contact_id-param="{{ contactId }}"
                data-contact-type-param="{{ type }}"
                >
                    <div class="contact-person contact-person-line">
                        <p class="contact-person-description">
                            <img class="image lazy"
                                data-src="/build/images/placeholder/wieland-logo.png"
                                alt="Wieland logo placeholder" width="40" height="40">
                            <strong>{{ b2bUnitName }}</strong>
                            <br>
                            <span>{{ firstName }} {{ lastName }}</span>
                            <br>
                            <a class="phone" href="tel:{{ phone }}">{{ phone }}</a>
                            <a class="email" href="#">{{ email }}</a>
                        </p>
                    </div>
                </div>
            </li>
        `,
    },
};


export default class extends Controller {
    static targets = [
        'otherContacts',

        'message',
        'spamProtectionToken',
        'spamProtectionGenerated',
        'spamProtectionEventCount',

        'contactForm',
        'contactFormRadioButtons',
        'contactFormDropdowns',
        'dynamicHiddenFormData',
        'formSubmitButton',
        'contactSent',
        'contactError',

        'contactPersonWrapper',
        'contactPersonToggle',
        'careerNotice'
    ];

    static outlets = [
        'mywieland'
    ];

    static values = {
        translations: Object
    };

    #isUserLoggedIn = false;
    #myWielandUserInfo = null;
    #myWielandContactData = null;
    #myWielandTabsConfig = null;

    connect() {
        this.#showLinkOtherContactPersons();
        this.#prepareContactForm();
        this.#checkUrlAndOpenPopup();
        this.#closeModal();
        this.#showEncryptedEmails();

        const updateUserState = () => {
            if (this.mywielandOutlet.isUserLoggedIn()) {
                this.#isUserLoggedIn = true;
                this.#renderLoggedInContactForm();
            }
        };

        $('body').on('mywieland:contactdata:updated', updateUserState);
        updateUserState();
    }

    sendContactForm(event) {
        event.preventDefault();
        const submitButton = this.formSubmitButtonTarget;
        submitButton.disabled = true;

        // In template .sr-only inputs are included in form data but not visible
        const formData = new FormData(this.contactFormTarget);
        const baseUrl = document.body.getAttribute('data-base-url');
        const ajaxUrl = `${baseUrl}/en/contact-form/submit`;

        fetch(ajaxUrl, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'OK') {
                    this.contactSentTarget.classList.remove('d-none');
                } else {
                    this.contactErrorTarget.classList.remove('d-none');
                }
            })
            .catch(() => {
                this.contactErrorTarget.classList.remove('d-none');
            })
            .finally(() => {
                this.contactFormTarget.classList.add('d-none');
                submitButton.disabled = false;
                this.contactFormTarget.reset();
            });
    }

    changeContactPersonType(event) {
        const selectedContactType = event.params.type;

        // Check if the selected contact type is 'career'
        if (selectedContactType === 'contact_career') {
            this.careerNoticeTarget.classList.remove('d-none');
        } else {
            this.careerNoticeTarget.classList.add('d-none');
        }

        this.contactPersonWrapperTargets.forEach((contactPersonWrapper) => {
            const selectElement = $(contactPersonWrapper).find('select');

            // Hide or show the dropdown based on the selected contact type
            if (contactPersonWrapper.dataset.type === selectedContactType) {
                contactPersonWrapper.classList.add('is-active');
                // Set the select element to be required and included in the form submission
                selectElement.attr('required', 'required').attr('name', 'contact_id');

                // If user is logged in, we need to add hidden fields with contact data
                if(this.#isUserLoggedIn) {
                    this.#addHiddenFormData(selectedContactType, selectElement.val());
                }
            } else {
                contactPersonWrapper.classList.remove('is-active');
                // Reset the select element to exclude it from the form submission
                selectElement.removeAttr('required').removeAttr('name');
            }
        });
    }

    selectContactPerson(event) {
        const selectedContactPerson = event.currentTarget.cloneNode(true);
        const selectedContactType = event.params.type;
        this.contactPersonToggleTargets.forEach((contactPersonToggle) => {
            if (contactPersonToggle.dataset.type === selectedContactType) {
                // Fill dropdown with selected contact person
                contactPersonToggle.innerHTML = selectedContactPerson.innerHTML;
            }
        });

        this.contactPersonWrapperTargets.forEach((contactPersonWrapper) => {
            // Append person id to the select element
            if (contactPersonWrapper.dataset.type === selectedContactType) {
                const selectElement = $(contactPersonWrapper).find('select');
                selectElement.append(new Option("", event.params.contactId));
                selectElement.val(event.params.contactId);
            }
        });

        // If user is logged in, we need to add hidden fields with contact data
        if(this.#isUserLoggedIn) {
            this.#addHiddenFormData(selectedContactType, String(event.params.contactId));
        }
    }

    #private

    #renderLoggedInContactForm() {
        const myWielandContactDataString = localStorage.getItem('myWielandContactData');
        const myWielandUserInfoString = localStorage.getItem('userInfo');
        if (myWielandContactDataString === null) {
            return;
        }
        this.#myWielandContactData = JSON.parse(myWielandContactDataString);
        this.#myWielandUserInfo = JSON.parse(myWielandUserInfoString);

        // Remove user data fields from form
        $(this.contactFormTarget).find(
          "input[name='firstname'], " +
          "input[name='lastname'], " +
          "input[name='country'], " +
          "input[name='phone'], " +
          "input[name='email'], " +
          "input[name='fromCompany']"
        ).remove();

        // add loggedin class
        this.contactFormTarget.classList.add('loggedin');

        // Render tabs
        this.#renderLoggedInTabs(this.#myWielandContactData);
    }

    #renderLoggedInTabs(myWielandContactData) {
        const tabConfigs = {
            salesRep: {
                label: this.translationsValue.salesTab,
                contacts: []
            },
            customerService: {
                label: this.translationsValue.customerServiceTab,
                contacts: []
            },
            technicalService: {
                label: this.translationsValue.technicalServiceTab,
                contacts: []
            }
        };

        // Prepare data for tabs from myWielandContactData
        myWielandContactData.forEach(({ b2bUnitName, salesRep, customerService, technicalService }) => {
            Object.entries({ salesRep, customerService, technicalService }).forEach(([key, contacts]) => {
                if (contacts.length > 0) {
                    tabConfigs[key].contacts.push({
                        b2bUnitName,
                        contact: contacts[0]
                    });
                }
            });
        });

        // Filter out empty tabs
        Object.keys(tabConfigs).forEach(key => {
            if (tabConfigs[key].contacts.length === 0) {
                delete tabConfigs[key];
            }
        });

        this.#myWielandTabsConfig = tabConfigs;

        // Render tabs
        const tabsHtml = Object.entries(tabConfigs).map(([type, { label, contacts }], index) => {
            if (contacts.length === 0) return ''; // Skip rendering if no contacts
            const existingTab = this.contactFormRadioButtonsTarget.querySelector(`input[id="${type}"]`);
            if (existingTab) return ''; // Skip if tab already exists
            return templates.tab.template
              .replace(/{{ type }}/g, type)
              .replace('{{ label }}', label)
              .replace('{{ checked }}', index === 0 ? 'checked' : '');
        }).join('');

        if(tabsHtml) {
            // Remove checked from inputs of this.contactFormRadioButtonsTarget.innerHTML
            this.contactFormRadioButtonsTarget.querySelectorAll('input').forEach((input) => {
                input.removeAttribute('checked');
            });

            // Remove general contact and products radio buttons
            $(this.contactFormRadioButtonsTarget)
              .find('input[id="contact_general_contact"], input[id="contact_products"], label[for="contact_general_contact"], label[for="contact_products"]')
              .remove();

            // Add tabsHtml to the radio buttons
            this.contactFormRadioButtonsTarget.innerHTML = tabsHtml + this.contactFormRadioButtonsTarget.innerHTML;

            // Render dropdowns
            const dropdownsHtml = Object.entries(tabConfigs).map(([type, { label, contacts }], index) => {
                if (contacts.length === 0) return ''; // Skip rendering if no contacts
                const existingDropdown = this.contactFormDropdownsTarget.querySelector(`[data-type="${type}"]`);
                if (existingDropdown) return ''; // Skip if dropdown already exists

                const contactListHtml = contacts.map(({ b2bUnitName, contact }) => {
                    return templates.contactItem.template
                      .replace('{{ contactId }}', contact.id)
                      .replace(/{{ type }}/g, type)
                      .replace('{{ b2bUnitName }}', b2bUnitName)
                      .replace('{{ firstName }}', contact.firstName)
                      .replace('{{ lastName }}', contact.lastName)
                      .replace(/{{ phone }}/g, contact.phone)
                      .replace('{{ email }}', contact.email);
                }).join('');

                return templates.dropdown.template
                  .replace(/{{ type }}/g, type)
                  .replace('{{ is-active }}', index === 0 ? 'is-active' : '')
                  .replace('{{ required }}', index === 0 ? 'required' : '')
                  .replace('{{ contactList }}', contactListHtml)
                  .replace('{{ dropdownToggle }}', this.translationsValue.dropdownToggle);
            }).join('');

            // remove is-active class from this.contactFormDropdownsTarget.innerHTML, data-contact-target="contactPersonWrapper"
            this.contactFormDropdownsTarget.querySelectorAll('[data-contact-target="contactPersonWrapper"]').forEach((contactPersonWrapper) => {
                contactPersonWrapper.classList.remove('is-active');
            });

            this.contactFormDropdownsTarget.innerHTML = dropdownsHtml + this.contactFormDropdownsTarget.innerHTML;
        }
    }

    #showLinkOtherContactPersons() {
        const contactsElement = document.getElementById('contacts');
        if (this.hasOtherContactsTarget && contactsElement !== null) {
            this.otherContactsTarget.classList.remove('d-none');
        }
    }

    #prepareContactForm() {
        if (this.hasMessageTarget &&
            this.hasSpamProtectionTokenTarget &&
            this.hasSpamProtectionGeneratedTarget &&
            this.hasSpamProtectionEventCountTarget) {
            this.messageTarget.addEventListener('keyup', () => {
                this.spamProtectionGeneratedTarget.value = this.spamProtectionTokenTarget.value;
                this.spamProtectionEventCountTarget.value = parseInt(this.spamProtectionEventCountTarget.value) + 1;
            });
        }
    }

    #addHiddenFormData(selectedContactType, contactId) {
        this.dynamicHiddenFormDataTarget.innerHTML = '';

        if(selectedContactType && contactId) {
            const contactPerson = this.#myWielandTabsConfig[selectedContactType].contacts.find(contact => contact.contact.id === contactId);

            if (contactPerson) {
                $('<input>', {
                    type: 'hidden',
                    name: 'contact_b2b_unit_name',
                    value: contactPerson.b2bUnitName
                }).appendTo(this.dynamicHiddenFormDataTarget);

                $('<input>', {
                    type: 'hidden',
                    name: 'contact_email',
                    value: contactPerson.contact.email
                }).appendTo(this.dynamicHiddenFormDataTarget);

                $('<input>', {
                    type: 'hidden',
                    name: 'contact_phone',
                    value: contactPerson.contact.phone
                }).appendTo(this.dynamicHiddenFormDataTarget);

                $('<input>', {
                    type: 'hidden',
                    name: 'contact_first_name',
                    value: contactPerson.contact.firstName
                }).appendTo(this.dynamicHiddenFormDataTarget);

                $('<input>', {
                    type: 'hidden',
                    name: 'contact_last_name',
                    value: contactPerson.contact.lastName
                }).appendTo(this.dynamicHiddenFormDataTarget);
            }
        }

        const userInfo = this.#myWielandUserInfo;
        if(userInfo) {
            $('<input>', {
                type: 'hidden',
                name: 'customer_id',
                value: userInfo.customerID
            }).appendTo(this.dynamicHiddenFormDataTarget);
            $('<input>', {
                type: 'hidden',
                name: 'firstname',
                value: userInfo.firstName
            }).appendTo(this.dynamicHiddenFormDataTarget);
            $('<input>', {
                type: 'hidden',
                name: 'lastname',
                value: userInfo.lastName
            }).appendTo(this.dynamicHiddenFormDataTarget);
            $('<input>', {
                type: 'hidden',
                name: 'country',
                value: userInfo.languageISOCode
            }).appendTo(this.dynamicHiddenFormDataTarget);
            $('<input>', {
                type: 'hidden',
                name: 'email',
                value: userInfo.email
            }).appendTo(this.dynamicHiddenFormDataTarget);
            $('<input>', {
                type: 'hidden',
                name: 'fromCompany',
                value: userInfo.defaultB2BUnitName
            }).appendTo(this.dynamicHiddenFormDataTarget);
        }
    }

    #checkUrlAndOpenPopup() {
        if (['#contact', '#kontakt'].includes(window.location.hash)) {
            $(this.element).modal('show');
        }

        // Automatically click the "Career" tab if it exists for unlogged users
        const careerTabLabel = this.contactFormRadioButtonsTarget.querySelector('label[for="contact_career"]');
        // Do not show the "Career" tab by default, if the user is not on a career related page
        const isCareerPage = location.pathname.indexOf('/karriere') > -1 || location.pathname.indexOf('/career') > -1;
        if (isCareerPage && careerTabLabel) {
            careerTabLabel.click();
        }
    }

    #closeModal() {
        $(this.element).on('hidden.bs.modal', () => {
            this.contactSentTarget.classList.add('d-none');
            this.contactErrorTarget.classList.add('d-none');
            this.contactFormTarget.classList.remove('d-none');
        });
    }

    #showEncryptedEmails() {
        // For each encrypted email link, decrypt the email and set the href attribute
        $(this.element).find('a[data-encrypted-mail]').each((index, element) => {
            const encrypted = element.getAttribute('data-encrypted-mail');
            const base64 = encrypted.split('').reverse().join('');
            const email = atob(base64);
            element.setAttribute('href', 'mailto:' + email);
            element.innerHTML = email;
        });
    }
}
